export const DATETIME_FORMAT = 'eeee, MM/dd/yyyy HH:mm';

export enum SSR_TYPES {
  WHEELCHAIR = 'WHEELCHAIRS',
  AISLECHAIR = 'AISLE CHAIRS',
  OTHER = 'OTHER',
  BLIND = 'BLIND',
  DEAF = 'DEAF',
  INFANT = 'PASSENGERS WITH INFANTS',
  BDAY = 'PASSENGER BIRTHDAY',
  NONENGLISH = 'NON-ENGLISH SPEAKER',
  ANIMAL = 'GUEST WITH SERVICE ANIMAL IN CABIN',
  ANIMALVERF = 'SERVICE ANIMAL DOCUMENTATION VERIFIED',
  MAAS = 'MEET AND ASSIST (NON-DISABILITY ASSISTANCE)',
  PET = 'PET IN CABIN',
  ARMED = 'ARMED GUEST (LEOs)',
  DEADHEAD = 'DEADHEADING FLIGHT CREW',
  DPNA = 'GUEST WITH INTELLECTUAL OR DEVELOPMENTAL DISABILITY',
  MEDICAL = 'MEDICAL CASE (DISCLOSED ALLERGY OR MEDICAL NEED',
  MILITARY = 'ACTIVE MILITARY',
  MILV = 'VERIFIED ACTIVE MILITARY',
  NRPS = 'NONREVENUE POSITIVE SPACE',
  NRSA = 'NONREVENUE SPACE AVAILABLE',
  PPOC = 'PERSONAL PORTABLE OXYGEN CONCENTRATOR',
  MINOR = 'UNACCOMPANIED MINOR (AGE 7-14)',
  SBLK = 'PLUSGRADE SEAT BLOCK',
  XSBLK = 'PLUSGRADE SEAT BLOCK',
  OPS = 'BREEZE OPS TEAM MEMBER',
}

export enum SSRs {
  SBLK = 'SBLK',
  XSBLK = 'XSBLK',
}

export const ALL_SSR_CODES = [
  'WCBD',
  'WCHR',
  'WCBW',
  'WCHS',
  'WCHC',
  'WCOB',
  'BLND',
  'DEAF',
  'INFT',
  'BDAY',
  'LANG',
  'SVAN',
  'SVAV',
  'MAAS',
  'PETC',
  'ARMD',
  'DCRW',
  'DPNA',
  'MEDA',
  'MIL',
  'MILV',
  'NRPS',
  'NRSA',
  'PPOC',
  'SBLK',
  'OPS'
];

export const SSR_LIST = [
  {
    type: SSR_TYPES.WHEELCHAIR,
    codes: ['WCBD', 'WCHR', 'WCBW', 'WCHS']
  },
  {
    type: SSR_TYPES.AISLECHAIR,
    codes: ['WCHC', 'WCOB']
  },
  {
    type: SSR_TYPES.OTHER,
    codes: [
      'BLND',
      'DEAF',
      'INFT',
      'BDAY',
      'LANG',
      'SVAN',
      'SVAV',
      'MAAS',
      'PETC',
      'ARMD',
      'DCRW',
      'DPNA',
      'MEDA',
      'MIL',
      'MILV',
      'NRPS',
      'NRSA',
      'PPOC',
      'SBLK',
      'OPS'
    ]
  }
];

export enum SeatAvailability {
  Unknown = 'Unknown',
  Reserved = 'Reserved',
  Blocked = 'Blocked',
  HeldForAnotherSession = 'HeldForAnotherSession',
  HeldForThisSession = 'HeldForThisSession',
  Open = 'Open',
  Missing = 'Missing',
  CheckedIn = 'CheckedIn',
  FleetBlocked = 'FleetBlocked',
  Restricted = 'Restricted',
  Broken = 'Broken',
  ReservedForPnr = 'ReservedForPnr',
  SoftBlocked = 'SoftBlocked',
  Unavailable = 'Unavailable'
}

export enum UnitType {
  /** Bar:27 */
  Bar = 'Bar',
  /** Bed:19 */
  Bed = 'Bed',
  /** BedOneOfThree:14 */
  BedOneOfThree = 'BedOneOfThree',
  /** BedOneOfTwo:17 */
  BedOneOfTwo = 'BedOneOfTwo',
  /** BedThreeOfThree:16 */
  BedThreeOfThree = 'BedThreeOfThree',
  /** BedTwoOfThree:15 */
  BedTwoOfThree = 'BedTwoOfThree',
  /** BedTwoOfTwo:18 */
  BedTwoOfTwo = 'BedTwoOfTwo',
  /** Bulkhead:13 */
  Bulkhead = 'Bulkhead',
  /** Closet:28 */
  Closet = 'Closet',
  /** Compartment:4 */
  Compartment = 'Compartment',
  /** Couchette:3 */
  Couchette = 'Couchette',
  /** Door:8 */
  Door = 'Door',
  /** Exit:20 */
  Exit = 'Exit',
  /** Galley:29 */
  Galley = 'Galley',
  /** GenericUnitResizableArea:22 */
  GenericUnitResizableArea = 'GenericUnitResizableArea',
  /** LabelRuler:21 */
  LabelRuler = 'LabelRuler',
  /** LargeSeat:2 */
  LargeSeat = 'LargeSeat',
  /** Lavatory:23 */
  Lavatory = 'Lavatory',
  /** LavatoryWithHandicappedFacilities:24 */
  LavatoryWithHandicappedFacilities = 'LavatoryWithHandicappedFacilities',
  /** Luggage:25 */
  Luggage = 'Luggage',
  /** MovableCompartmentDivider:26 */
  MovableCompartmentDivider = 'MovableCompartmentDivider',
  /** MovieScreen:30 */
  MovieScreen = 'MovieScreen',
  /** None:0 */
  None = 'None',
  /** NormalSeat:1 */
  NormalSeat = 'NormalSeat',
  /** Other:12 */
  Other = 'Other',
  /** Stairs:9 */
  Stairs = 'Stairs',
  /** Storage:31 */
  Storage = 'Storage',
  /** Table:5 */
  Table = 'Table',
  /** Wall:6 */
  Wall = 'Wall',
  /** Window:7 */
  Window = 'Window',
  /** Wing:10 */
  Wing = 'Wing'
}

export enum FlightStatus {
  Delayed = 'Delayed',
  Canceled = 'Canceled',
  OnTime = 'Scheduled',
  Taxing = 'Taxing',
  Completed = 'Completed',
  Airborne = 'Airborne'
}

export enum CrewRank {
  Captain = 'CA',
  FirstOfficer = 'FO',
  FlightAttendant = 'FA'
}

export enum AircrafType {
  E195 = 'E95',
  E190 = 'E90',
  A220 = '223'
}

export enum LiftStatus {
  DEFAULT = 'Default',
  CHECKED_IN = 'CheckedIn',
  NO_SHOW = 'NoShow',
  BOARDED = 'Boarded',
}

// FOR EASTER EGG
export interface OpsTeamMember {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export const OPS_TEAM_MEMBERS: OpsTeamMember[] = [
  {
    firstName: 'Jeffrey',
    lastName: 'Stapleton',
    dateOfBirth: "1900-11-08T00:00:00"
  },
  {
    firstName: 'Brendan',
    lastName: 'Bliss',
    dateOfBirth: "1900-05-17T00:00:00"
  },
  {
    firstName: 'Michael',
    lastName: 'Ingram',
    dateOfBirth: "1900-07-27T00:00:00"
  },
  {
    firstName: 'Jeff',
    lastName: 'DeGraw',
    dateOfBirth: "1900-04-10T00:00:00"
  },
  {
    firstName: 'Wyatt',
    lastName: 'LeFevre',
    dateOfBirth: "1900-09-25T00:00:00"
  },
  {
    firstName: 'Maxwell',
    lastName: 'Hamilton',
    dateOfBirth: "1900-06-29T00:00:00"
  },
  {
    firstName: 'A Reum',
    lastName: 'Jung',
    dateOfBirth: "1900-10-24T00:00:00"
  },
  {
    firstName: 'Christopher',
    lastName: 'Garner',
    dateOfBirth: "1900-02-03T00:00:00"
  },
  {
    firstName: 'Hunter',
    lastName: 'Hall',
    dateOfBirth: "1900-05-06T00:00:00"
  },
  {
    firstName: 'Matz',
    lastName: 'Matsumoto',
    dateOfBirth: "1900-04-14T00:00:00"
  }
]
