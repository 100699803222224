import styled from '@emotion/styled';
import { Flight, Passenger, SSR } from '@models';
import { useCrewHubStore } from '@hooks';
import {
  paxLegSsrs,
  findLeg,
  findSelectedFlightFromState
} from '@utilities';
import { IonCol, IonGrid, IonModal, IonRow } from '@ionic/react';
import { GuestInfoModal } from '../guest-info-modal/guest-info-modal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SsrIcon } from '../ssr-icon/ssr-icon';

export interface GuestListItemProps {
  passenger: Passenger;
}
const StyledGuestListItem = styled.div(
  ({ theme }) => `
    font-family: ${theme.fonts.openSans};
    font-weight: bold;
    background-color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.medium};
    color: #f4f6f8;
    border-bottom: ${theme.borders.smallGrey};
    border-color: #677f9b;
    padding: 1px ${theme.padding.leftEdge} 1px 0;
  `
);

const Row = styled(IonRow)`
  height: 40px;
`;

const Icon = styled.div`
  float: right;
`;

export function GuestListItem({ passenger }: GuestListItemProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const state = useCrewHubStore();
  const { flightId } = useParams<{ flightId?: string }>();
  const flight: Flight | undefined =
    state.flights && findSelectedFlightFromState(state.flights, flightId);
  function setPaxAndOpenModal() {
    setShowModal(true);
  }

  // handle breeze thru
  const leg = findLeg(flight, passenger);
  const ssrs: SSR[] = paxLegSsrs(leg, passenger);
  const hasSsrs = ssrs.length > 0;
  const passengerSeatNumber: string | null | undefined = leg?.unitDesignator;

  return (
    <StyledGuestListItem data-testid="passenger">
      <IonGrid>
        <Row onClick={setPaxAndOpenModal} data-testid="ion-row">
          <IonCol size="2">
            <div data-testid="seatNum">{passengerSeatNumber}</div>
          </IonCol>
          <IonCol size="5">
            {passenger?.name.first} {passenger?.name.last}
          </IonCol>
          {hasSsrs ? (
            <IonCol size="5">
              <Icon>
                <SsrIcon ssrs={ssrs} size={30}></SsrIcon>
              </Icon>
            </IonCol>
          ) : (
            <IonCol size="5" data-testid="no-icon-col" />
          )}
        </Row>
      </IonGrid>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <GuestInfoModal
          setShowModal={setShowModal}
          passenger={passenger}
        />
      </IonModal>
    </StyledGuestListItem>
  );
}
