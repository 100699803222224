/* eslint-disable-next-line */
import {IonSpinner} from "@ionic/react";
import styled from "@emotion/styled";
import CrewHubLogo from "@components/lib/crew-hub-logo/crew-hub-logo";

export interface LoadingScreenModalProps {
  loadingMessage: string;
  isOpen: boolean;
}

const StyledModal = styled.div(
  ({theme}) => `
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.lightGrey};
    font-family: ${theme.fonts.worksSans};
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const SubHeader = styled.div`
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerContainer = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.primary};
    margin: 20px 0px;
  `
);

export function LoadingScreenModal({loadingMessage, isOpen}: LoadingScreenModalProps) {
  const ModalContainer = styled.div`
    display: ${isOpen ? 'flex' : 'none'};
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    `;

  return (
    <ModalContainer>
      <StyledModal>
        <div>
          <LogoContainer>
            <CrewHubLogo data-testid="logo" pxHeight={'40px'} pxWidth={'330px'}/>
          </LogoContainer>
          <SubHeader>Please Wait While We Load {loadingMessage}</SubHeader>
          <SpinnerContainer>
            <IonSpinner name="crescent" color="light"></IonSpinner>
          </SpinnerContainer>
        </div>
      </StyledModal>
    </ModalContainer>
  )
}

export default LoadingScreenModal;
