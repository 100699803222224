import { ALL_SSR_CODES, Leg, OPS_TEAM_MEMBERS, OpsTeamMember, Passenger, SSR } from "@models";

function compareMonthAndDay(date1: string | undefined, date2: string | undefined) {
  if (!date1 || !date2) {
    return false
  }

  // Convert date1 and date2 into Date objects
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Get month and day from each date (months are 0-indexed in JavaScript, so we add 1 to match human-readable format)
  const month1 = d1.getMonth();
  const day1 = d1.getDate();
  
  const month2 = d2.getMonth();
  const day2 = d2.getDate();

  // Compare month and day
  return month1 === month2 && day1 === day2;
}

function isOpsTeamMember(pax: Passenger) {
  const opsTeamMember = OPS_TEAM_MEMBERS.find((ops: OpsTeamMember) => {
    return ops.firstName === pax.name.first &&
           ops.lastName === pax.name.last &&
           compareMonthAndDay(ops.dateOfBirth, pax.passengerInformation.dateOfBirth)
  })

  return !!opsTeamMember
}

export function paxLegSsrs(leg: Leg | undefined, passenger: Passenger | undefined): SSR[]  {
  if (!leg) {
    return []
  }

  // filter ssrs
  let ssrs: SSR[] = leg.ssrs.filter((ssr) => ALL_SSR_CODES.includes(ssr.code));

  if (!passenger) {
    return ssrs;
  }

  // EASTER EGG!!
  // ADD CROWN ICON FOR OPS TEAM MEMBERS
  if (passenger && isOpsTeamMember(passenger)) {
    const opsSsr: SSR = {code: "OPS", name: "Breeze Ops Team Member"};
    ssrs.push(opsSsr);
  }

  return ssrs;
}
