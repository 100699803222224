import { SSR } from '@models';
import wheelchair from '../../../../../libs/assets/src/lib/wheelchair.svg';
import infant from '../../../../assets/src/lib/infant.svg';
import birthday from '../../../../assets/src/lib/birthday.svg';
import serviceAnimal from '../../../../assets/src/lib/service-animal.svg';
import petInCabin from '../../../../assets/src/lib/pet-in-cabin.svg';
import medicalCase from '../../../../assets/src/lib/medical-case.svg';
import blank from '../../../../assets/src/lib/blank.svg';
import plainMultiple from '../../../../assets/src/lib/plain-multiple.svg';
import seatBlocked from '../../../../assets/src/lib/seat-blocked.svg';
import crown from '../../../../assets/src/lib/crown.svg';
import styled from '@emotion/styled';
import { SsrIconModel } from '@models/src/lib/ssr-icon';

export const SSR_PRIORITY: SsrIconModel[] = [
//verify ssr priority order when adding icons. Original order: Wheelchair, Infant, Blind, Deaf, Lang, Service Animal, MAAS, PETC, ARMD...
//ssr with icon should be higher priority than ssr without icon until that ssr gets an icon
  {
    icon: wheelchair,
    code: 'WCBD',
    description: 'Test'
  },
  {
    icon: wheelchair,
    code: 'WCHR',
    description: 'Test'
  },
  {
    icon: wheelchair,
    code: 'WCBW',
    description: 'Test'
  },
  {
    icon: wheelchair,
    code: 'WCHS',
    description: 'Test'
  },
  {
    icon: wheelchair,
    code: 'WCHC',
    description: 'Test'
  },
  {
    icon: wheelchair,
    code: 'WCOB',
    description: 'Test'
  },
  {
    icon: infant,
    code: 'INFT',
    description: 'Test'
  },
  {
    icon: serviceAnimal,
    code: 'SVAV',
    description: 'Test'
  },
  {
    icon: serviceAnimal,
    code: 'SVAN',
    description: 'Test'
  },
  {
    icon: petInCabin,
    code: 'PETC',
    description: 'Test'
  },
  {
    icon: medicalCase,
    code: 'MEDA',
    description: 'Test'
  },
  {
    icon: seatBlocked,
    code: 'XSBLK', // we only want to diplay the seat block icon on the seat blocked, not the passenger
    description: 'Passenger has blocked an adjoining seat'
  },
  {
    icon: crown,
    code: 'OPS',
    description: 'Breeze ops team member'
  },
  {
    icon: birthday,
    code: 'BDAY',
    description: 'It is your birthday'
  },
  {
    icon: blank,
    code: 'BLND',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'DEAF',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'LANG',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'MAAS',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'ARMD',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'DCRW',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'DPNA',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'MIL',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'MILV',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'NRPS',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'NRSA',
    description: 'Test'
  },
  {
    icon: blank,
    code: 'PPOC',
    description: 'Test'
  }
];

export interface SsrIconProps {
  ssrs: SSR[];
  size: number;
}

export function SsrIcon(props: SsrIconProps) {
  const Container = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: ${props.size}px;
    height: ${props.size}px;
  `;

  const MutlipleSsrCards = styled.img`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `;

  const NestedIcon = styled.img`
    position: absolute;
    top: ${props.size * 0.32}px;
    left: ${props.size * 0.18}px;
    width: 50%;
    height: 50%;
  `;

  const SingleIcon = styled.img`
    position: relative;
    top: ${props.size * 0.1}px;
    left: 0;
    width: 80%;
    height: 80%;
  `;

  const ssrs = props.ssrs.filter((ssr) =>
    SSR_PRIORITY.some((x) => x.code === ssr.code)
  );
  const hasMultipleSsrs = ssrs.length > 1;

  let priorityIndex = Number.MAX_VALUE;

  props.ssrs.map((ssr) => {
    const i = SSR_PRIORITY.findIndex(
      (prioritySsr) => prioritySsr.code === ssr.code
    );
    if (i !== -1 && i < priorityIndex) {
      priorityIndex = i;
    }
  });

  if (hasMultipleSsrs) {
    return (
      <Container>
        <MutlipleSsrCards alt="multiple-ssrs" src={plainMultiple} />
        {priorityIndex === Number.MAX_VALUE ? (
          <></>
        ) : (
          <NestedIcon alt="ssr-icon" src={SSR_PRIORITY[priorityIndex].icon} />
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        {priorityIndex === Number.MAX_VALUE ? (
          <></>
        ) : (
          <SingleIcon alt="ssr-icon" src={SSR_PRIORITY[priorityIndex].icon} />
        )}
      </Container>
    );
  }
}

export default SsrIcon;
